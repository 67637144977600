import React, { useEffect, useState } from "react";
import { useNavigate, Link  } from "react-router-dom";
import Header from "../../../components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "../../../axiosConfig";

interface Ingredient {
  id: string;
  stock_name: string;
  quantity: number;
  id_ingredient: string;
}

interface Product {
  id: string;
  name: string;
  price: number;
  ingredients: Ingredient[];
}

interface Stock {
  id: string;
  name: string;
  unity_price: number;
}

export const SeeProducts = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([]);
  const [stock, setStock] = useState<Stock[]>([]);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const { data } = await api.get("product");
      setProducts(data.data);
    } catch (err) {
      setMessage("Erro ao carregar produtos.");
      console.error("Erro ao carregar produtos:", err);
    }
  };

  const fetchStock = async () => {
    try {
      const { data } = await api.get("stock");
      console.log(data)
      setStock(data.allStock);
    } catch (err) {
      setMessage("Erro ao carregar estoque.");
      console.error("Erro ao carregar o estoque:", err);
    }
  };


  const deleteProducts = async (productId: string) => {
    
      if (window.confirm("Tem certeza que deseja APAGAR esse item")){
        try{
          await api.delete(`product/${productId}`)
          setProducts(products.filter((product) => product.id !== productId))
          setMessage("o produto foi deletado com sucesso")
        }catch (err) {
        setMessage("Erro ao deletar o Produto.");
         console.error("Erro ao deletar o Produto:", err);
      }
      }
    }
  


  useEffect(() => {
    fetchProducts();
    fetchStock();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-400">
      <Header />
      <main className="flex-1 flex flex-col items-center p-5">
        <div className="w-full max-w-6xl">
          <div className="flex items-center justify-between mb-6">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 font-bold flex items-center"
            >
              <ArrowBackIcon className="mr-2" />
              Voltar
            </button>
          </div>
          <h1 className="text-2xl lg:text-4xl font-bold mb-6 text-center text-gray-800">
            Listagem de Mercadorias
          </h1>

          {message && (
            <div
              className={`mb-4 p-4 border-2 rounded ${
                message.includes("sucesso")
                  ? "bg-green-200 border-green-400 text-green-800"
                  : "bg-red-200 border-red-400 text-red-800"
              }`}
            >
              {message}
            </div>
          )}

          <div className="overflow-x-auto shadow-lg rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 bg-white rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-3 text-left text-xs lg:text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Mercadoria
                  </th>
                  <th className="px-4 py-3 text-left text-xs lg:text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Ingredientes
                  </th>
                  <th className="px-4 py-3 text-left text-xs lg:text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Preço
                  </th>
                  <th className="px-4 py-3 text-left text-xs lg:text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Descrição
                  </th>
                  <th className="px-4 py-3 text-left text-xs lg:text-sm font-semibold text-gray-700 uppercase tracking-wider">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map(product => (
                  <tr key={product.id} className="hover:bg-gray-100 transition duration-150">
                    <td className="px-4 py-3 text-xs lg:text-sm text-gray-800">
                      {product.name}
                    </td>
                    <td className="px-4 py-3 text-xs lg:text-sm text-gray-600">
                      {product.ingredients.map(ingredient => ingredient.stock_name).join(', ')}
                    </td>
                    <td className="px-4 py-3 text-xs lg:text-sm text-gray-600">
                     
                      R$ {product.price.toFixed(2)}
                      
                    </td>
                    <td className="px-4 py-3 text-xs lg:text-sm text-gray-600">
                      Produto de alta qualidade
                    </td>
                    <td className="px-4 py-3 flex flex-col space-y-2">
                      <button
                      
                        className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-xs lg:text-sm transition"
                      >
                        <Link
                      to={`/edit-ingredients/${product.id}`}
                      >

                        Editar Ingredientes
                      </Link>
                      </button>
                      <button
                        className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 text-xs lg:text-sm transition"
                      >
                        <Link
                      to={`/product/${product.id}`}>
                       Editar Preço
                      </Link>
                        
                      </button>
                      <button
                      onClick={()=> deleteProducts(product.id)}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-xs lg:text-sm transition"
                      >
                        Remover
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center mt-8">
            <button
              onClick={() => navigate(-1)}
              className="px-6 py-3 bg-red-500 text-white rounded hover:bg-red-600 text-lg lg:text-xl transition"
            >
              Voltar
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
