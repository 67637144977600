import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import api from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Ingredient {
  id: string;
  stock_name: string;
  quantity: number;
  id_ingredient: string;
}

interface Product {
  id: string;
  name: string;
  price: number;
  ingredients: Ingredient[];
}

interface Stock {
  id: string;
  name: string;
  unity_price: number;
}

export const ProfitMargin = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([]);
  const [stock, setStock] = useState<Stock[]>([]);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const { data } = await api.get("product");
      setProducts(data.data);
    } catch (err) {
      setMessage("Erro ao carregar produtos.");
      console.error("Erro ao carregar produtos:", err);
    }
  };

  const fetchStock = async () => {
    try {
      const { data } = await api.get("stock");
      setStock(data.allStock);
    } catch (err) {
      console.error("Erro ao carregar o estoque:", err);
      setMessage("Erro ao carregar estoque.");
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchStock();
  }, []);


  const calculateTotalCost = (ingredients: Ingredient[]): number => {
    return ingredients.reduce((total, ingredient) => {
 
      const stockItem = stock.find(item => item.id === ingredient.id_ingredient);
      if (stockItem) {
      
        return total + stockItem.unity_price * ingredient.quantity;
      }
      return total;
    }, 0);
  };

  
  const calculateProfitMargin = (productPrice: number, totalCost: number): string => {
    const margin = ((productPrice - totalCost) / productPrice) * 100;
    return margin.toFixed(2) + "%";
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gray-400">
      <Header />
      <main className="flex-1 flex flex-col mt-10 items-center p-6">
        <div className="flex items-center justify-between w-full max-w-6xl mb-6">
          <button
            onClick={() => navigate(-1)}
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 font-bold flex items-center"
          >
            <ArrowBackIcon className="mr-2" />
            Voltar
          </button>
        </div>
        <h1 className="text-xl lg:text-3xl font-bold mb-6">
          Margem de Lucro dos Produtos
        </h1>
        {message && (
          <div
            className={`mb-4 p-4 border-2 rounded ${
              message.includes("sucesso")
                ? "bg-green-200 border-green-400 text-green-800"
                : "bg-red-200 border-red-400 text-red-800"
            }`}
          >
            {message}
          </div>
        )}
        <div className="w-full flex flex-col items-center gap-4 max-w-6xl md:px-24 lg:px-44">
          {products.length > 0 ? (
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="border p-2">Produto</th>
                  <th className="border p-2">Preço de Venda</th>
                  <th className="border p-2">Preço Gasto</th>
                  <th className="border p-2">Margem de Lucro</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => {
                  const totalCost = calculateTotalCost(product.ingredients);
                  const profitMargin = calculateProfitMargin(product.price, totalCost);

                  return (
                    <tr key={product.id} className="text-center">
                      <td className="border p-2">{product.name}</td>
                      <td className="border p-2">{product.price.toFixed(2)}</td>
                      <td className="border p-2">{totalCost.toFixed(2)}</td>
                      <td className="border p-2">{profitMargin}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-600">Nenhum produto encontrado</p>
          )}
        </div>
      </main>
    </div>
  );
};
