import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import api from "../../../axiosConfig";

interface Ingredient {
  id: number;
  id_ingredient: string;
  id_product: string;
  quantity: number;
  stock_name: string;
  product_name: string;
}

interface Product {
  id: string;
  name: string;
  ingredients: Ingredient[];
}

export const EditIngredients = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [product, setProduct] = useState<Product | null>(null);
  const [message, setMessage] = useState<string>("");
  const [newName, setNewName] = useState<string>("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data } = await api.get(`/product/${id}`);
        const productData = data.product[0];
        setProduct(productData);
        setNewName(productData.name);
      } catch (err) {
        setMessage("Erro ao carregar o produto.");
        console.error("Erro ao carregar o produto", err);
      }
    };
    fetchProduct();
  }, [id]);

  const handleQuantityChange = async (ingredientId: number, newQuantity: number) => {
    if (product) {
      try {
        await api.put(`/ingredient/${ingredientId}`, {
          quantity: newQuantity,
        });
        const updatedIngredients = product.ingredients.map((ingredient) =>
          ingredient.id === ingredientId
            ? { ...ingredient, quantity: newQuantity }
            : ingredient
        );
        setProduct({ ...product, ingredients: updatedIngredients });
        setMessage("Quantidade atualizada com sucesso.");
      } catch (err) {
        setMessage("Erro ao atualizar a quantidade.");
        console.error("Erro ao atualizar a quantidade", err);
      }
    }
  };

  const handleRemoveIngredient = async (ingredientId: number) => {
    if (product) {
      try {
        await api.delete(`/ingredient/${ingredientId}`);
        const updatedIngredients = product.ingredients.filter(
          (ingredient) => ingredient.id !== ingredientId
        );
        setProduct({ ...product, ingredients: updatedIngredients });
        setMessage("Ingrediente removido com sucesso.");
      } catch (err) {
        setMessage("Erro ao remover o ingrediente.");
        console.error("Erro ao remover o ingrediente", err);
      }
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleQuantityInputChange = (ingredientId: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      handleQuantityChange(ingredientId, value);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (product) {
      try {
        const response = await api.put(`/product/${id}`, {
          ...product,
          name: newName,
          ingredients: product.ingredients
        });
        console.log("Resposta da API:", response.data); 
        setMessage("Produto atualizado com sucesso.");
        await reloadProductData(); 
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (err) {
        setMessage("Erro ao atualizar o produto.");
        console.error("Erro ao atualizar o produto:", err);
      }
    }
  };

  const reloadProductData = async () => {
    try {
      const { data } = await api.get(`/product/${id}`);
      const productData = data.product[0];
      setProduct(productData);
      setNewName(productData.name);
    } catch (err) {
      setMessage("Erro ao carregar o produto.");
      console.error("Erro ao carregar o produto", err);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Volta para a tela anterior
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-400">
      <Header />
      <main className="flex-1 flex flex-col mt-10 items-center p-6">
        <h1 className="text-xl lg:text-3xl font-bold mb-7">Editar Ingredientes</h1>
        {message && (
          <div
            className={`mb-4 p-4 border-2 rounded ${
              message.includes("sucesso")
                ? "bg-green-200 border-green-400 text-green-800"
                : "bg-red-200 border-red-400 text-red-800"
            }`}
          >
            {message}
          </div>
        )}
        <button 
          onClick={handleGoBack} 
          className="mb-4 px-4 py-2 bg-gray-500 text-white font-bold rounded"
        >
          Voltar
        </button>
        {product && (
          <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <div className="mb-4">
              <label className="block mb-2 font-bold text-gray-700">Nome do Produto:</label>
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                className="px-4 py-2 border-2 border-gray-300 rounded w-full bg-gray-100"
              />
            </div>
            {product.ingredients.map((ingredient) => (
              <div key={ingredient.id} className="mb-4 p-4 border-2 border-gray-300 rounded">
                <h3 className="font-bold">{ingredient.stock_name}</h3>
                <div className="flex items-center mt-2">
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(ingredient.id, Math.max(0, ingredient.quantity - 1))}
                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-700"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={ingredient.quantity}
                    onChange={(e) => handleQuantityInputChange(ingredient.id, e)}
                    className="mx-2 px-2 py-1 border-2 border-gray-300 rounded w-24 text-center"
                  />
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(ingredient.id, ingredient.quantity + 1)}
                    className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-700"
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRemoveIngredient(ingredient.id)}
                    className="ml-4 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-700"
                  >
                    Remover
                  </button>
                </div>
              </div>
            ))}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white font-bold rounded"
            >
              Atualizar Produto
            </button>
          </form>
        )}
      </main>
    </div>
  );
};
