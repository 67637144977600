import { useState } from "react";
import { login } from "../../hooks/Login";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
export const Login = () => {
    const [pass, setPass] = useState<string>();
    const nav = useNavigate();
    const newLogin = () => {
        const isTrue = login(String(pass));

        if(isTrue){
            nav("/")
        }

        return false;
    }
    return (
        <div className="bg-gray-400 h-screen">
            <Header/>
            <h1 className="text-center pt-5 text-3xl text-white font-bold">Login</h1> 

            <div className="flex flex-col mt-10 gap-5 justify-center items-center">
                <label className="text-white text-xl font-bold">Coloque sua senha:</label>
                <input onChange={(e) => setPass(e.target.value)} className="p-2 w-10/12" type="password" placeholder="Sua senha aqui:"/>

                <button onClick={() => newLogin()} className="bg-yellow-400 w-32 p-2 rounded-lg">Entrar</button>
            </div>
        </div>
    )                   
}                                                       