export const login = (password:string) => {
    
    if (password !== "minimessi2018"){
        return false;
    }

    localStorage.setItem("session", "true");

    return true;
}

