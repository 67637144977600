import axios from 'axios';


export const IpBlock = async () => {
  let ip;

      try {
          const response = await axios.get('https://api.ipify.org?format=json');
          ip = response.data.ip;
          return String(ip);
        } catch (error) {
          console.error('Erro ao obter o IP', error);
        }
}