import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import {
  Home,
  Stock,
  HomeClients,
  HomeOrder,
  ListOrder,
  ClientsList,
  LoginClients,
  PayClient,
  RegisterStock,
  NewOrder,
  Finance,
  RegisterExpenses,
  FinishOrder,
  RegisterTable,
  TableList,
  Spends,
  EditClient,
  Uppers,
  ListagemStock,
  AddItem,
  EditStock,
  EditSpends,
  RegisterProducts,
  AddIngredient,
  SeeLogin,
  Login,
  ProfitMargin,
  SeeProducts,
  EditProductPrice,
  EditIngredients
} from "../containers";
import { IpBlock } from "../hooks/ipBlock";
import { useEffect, useState } from "react";

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const session = localStorage.getItem("session");

  if (session !== "true") {
    return <Navigate to="/login" />;
  }

  return element;
};

export const Routers = (): JSX.Element => {
  const [ip, setIp] = useState<string>("");
  const allowedIps = ["177.185.47.24", "152.250.175.56", "170.244.28.7", "177.185.44.32", "170.244.29.230"];

  useEffect(() => {
    const fetchIp = async () => {
      const newIp = await IpBlock();
      setIp(String(newIp));
    };

    fetchIp();
  }, []);

  return (
    <Router>
      {allowedIps.includes(ip) && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pedidos" element={<HomeOrder />} />
          <Route path="/listapedidos" element={<ListOrder />} />
          <Route path="/listaclientes" element={<ClientsList />} />
          <Route path="/loginclientes" element={<LoginClients />} />
          <Route path="/clientesapagar" element={<PayClient />} />
          <Route path="/registroestoque" element={<RegisterStock />} />
          <Route path="/novopedido" element={<NewOrder />} />
          <Route path="/cadastrodegastos" element={<RegisterExpenses />} />
          <Route path="/finalizarpedido/:id" element={<FinishOrder />} />
          <Route path="/cadastrarmesa" element={<RegisterTable />} />
          <Route path="/listagemesas" element={<TableList />} />
          <Route path="/gastos" element={<Spends />} />
          <Route path="/client/:id" element={<EditClient />} />
          <Route path="/lucros" element={<Uppers />} />
          <Route path="/listagemestoque" element={<ListagemStock />} />
          <Route path="/add-item/:id" element={<AddItem />} />
          <Route path="/stock/:id" element={<EditStock />} />
          <Route path="/edit-spends/:id" element={<EditSpends />} />
          <Route path="/cadastrarprodutos" element={<RegisterProducts />} />
          <Route path="/add-ingredient" element={<AddIngredient />} />
          <Route path="/vercliente/:id" element={<SeeLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/margemdelucro" element={<ProfitMargin />} />
          <Route path="/vermercadoria" element={<SeeProducts />} />
          <Route path="/product/:id" element={<EditProductPrice />} />
          <Route path="/edit-ingredients/:id" element={<EditIngredients />} />
          

          <Route path="/estoque" element={<PrivateRoute element={<Stock />} />} />
          <Route path="/financas" element={<PrivateRoute element={<Finance />} />} />
          <Route path="/clientes" element={<PrivateRoute element={<HomeClients />} />} />
        </Routes>
      )}
    </Router>
  );
};
