import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import api from "../../../axiosConfig";

interface Product {
  id: string;
  name: string;
  price: number;
}

export const EditProductPrice = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const [product, setProduct] = useState<Product | null>(null);
  const [newPrice, setNewPrice] = useState<number | "">("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data } = await api.get(`/product/${id}`);
        const productData = data.product[0]; 
        setProduct(productData);
        setNewPrice(productData.price); 
        console.log("Produto carregado:", productData); 
      } catch (err) {
        setMessage("Erro ao carregar o produto.");
        console.error("Erro ao carregar o produto", err);
      }
    };
    fetchProduct();
  }, [id]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPrice(value === "" ? "" : parseFloat(value));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (product && newPrice !== "") {
      try {
        await api.put(`/product/${id}`, { ...product, price: newPrice });
        setMessage("Preço do produto atualizado com sucesso.");
        setTimeout(() => {
          navigate(-1); 
        }, 2000);
      } catch (err) {
        setMessage("Erro ao atualizar o preço do produto.");
        console.error("Erro ao atualizar o preço do produto:", err);
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-400">
      <Header />
      <main className="flex-1 flex flex-col mt-10 items-center p-6">
        <h1 className="text-xl lg:text-3xl font-bold mb-7">Editar Preço do Produto</h1>
        {message && (
          <div
            className={`mb-4 p-4 border-2 rounded ${
              message.includes("sucesso")
                ? "bg-green-200 border-green-400 text-green-800"
                : "bg-red-200 border-red-400 text-red-800"
            }`}
          >
            {message}
          </div>
        )}
        {product && (
          <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <div className="mb-4">
              <label className="block mb-2 font-bold text-gray-700">Nome do Produto:</label>
              <p className="px-4 py-2 border-2 border-gray-300 rounded w-full bg-gray-100">{product.name}</p>
            </div>
            <label className="block mb-2">
              Novo Preço:
              <input
                type="number"
                value={newPrice === "" ? "" : newPrice}
                onChange={handlePriceChange}
                className="px-4 py-2 border-2 border-gray-300 rounded w-full"
              />
            </label>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white font-bold rounded"
            >
              Atualizar Preço
            </button>
          </form>
        )}
      </main>
    </div>
  );
};
